.App {
    width: 100vw;
    height: 100vh;
}

body{
    margin: 0;
    padding: 0;
}
button:hover{
    cursor:pointer;
}
.listitem {
    text-decoration: none;
    color: black;
}
.Navbar{
    background-color: rgb(133, 131, 122);
    width: 100%;
    height: 70px;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    align-items: center;
}

.Navbar .leftSide {
    flex: 50%;
    display: flex;
    font-size: 35px;
    justify-content: left;
    align-items: left;
    margin-left: 15px;
    text-decoration: none;
}

.Navbar .leftSide a {
    color: white;
}

.Navbar .rightSide {
    flex: 50%;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
}

.Navbar .rightSide .links a{
    font-size: 20px;
    font-style: oblique;
    font-weight: 600;
    margin-left: 30px;
    color:white;
    text-decoration: none;
}


.Navbar .links button {
    background-color: rgb(133, 131, 122);
    margin-left: 30px;
    display: inline-block;
    text-align: center;
    color: white;
    border: rgb(41, 41, 1);
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 20px;
}

#login {
    background-color: rgb(133, 131, 122);
    font-weight: 600;
    color: white;
    border: none;
    font-size: 1.5rem;
}
#dropdown {
    margin-right: 10px;
    background-color: #ff0055;
    border-color: #ff0055;
  }
  #menu {
    margin-right: 65px;
  }
  
@media (max-width: 700px) {
    #dropdown {
      margin-right: 7px;
      background-color: #ff0055;
      border-color: #ff0055;
    }
    #menu {
      margin-right: 72.5px;
    }
  }

@media (max-width: 450px) {
    #dropdown {
        margin-right: 5px;
    }
    #menu {
        margin-right: 60px;
    }
}

@media (max-width: 350px) {
    #dropdown {
        margin-right: 0px;
    }
    #menu {
        margin-right: 55px;
    }
}


@media only screen and (max-width: 650px){
    .Navbar .leftSide{
        font-size: 25px;
    }
    .Navbar .rightSide .links{
        font-size: 15px;
        margin-left: 10px;
        display: flex;
    }
    .Navbar .rightSide .links a{
        margin-left:10px;
        
        font-size: 10px;
    }
    .Navbar .links button {
        font-size: 15px;
        margin-left: 10px;
    } 
}
