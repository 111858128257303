.heart {
  z-index: 20;
  width: 8%;
  border-color:white;
  /* margin-left: 18vw; */
  /* margin-right: -4rem; */
  margin-bottom: 8vw;
  flex: end;
}

li {
  list-style: none;
  text-align: center;
  align-items: center;
  padding: 0.25rem;
  padding-top: 0.5rem;
  width: 40%;
  margin-top: 60px;
  margin: 1rem;
  background-color: rgb(245, 245, 245);
  border-radius: 5px;
  flex-basis: 28%;
  z-index: 20;
}

@media (max-width: 1228px) {
  li {
    list-style: none;
    text-align: center;
    align-items: center;
    padding: 0.25rem;
    padding-top: 0.5rem;
    width: 40%;
    margin-top: 60px;
    margin: 1rem;
    background-color: rgb(245, 245, 245);
    border-radius: 5px;
    flex-basis: 28%;
  }
}

@media (max-width: 553px) {
  li {
    list-style: none;
    text-align: center;
    align-items: center;
    padding: 0.25rem;
    padding-top: 0.5rem;
    width: 40%;
    margin-top: 60px;
    margin: 1rem;
    background-color: rgb(245, 245, 245);
    border-radius: 5px;
    flex-basis: 40%;
  }
}
img {
  border-radius: 5px;
  align-self: center;
  max-width: 300px;
  max-height: 200px;
  z-index: 1;
  margin-left: 2vw;
}

.Title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
}
a {
  text-decoration: none;
}
.button {
  font: inherit;
  padding: 0.5rem 2.5rem;
  border: 1px solid #ff0055;
  border-radius: 4px;
  background: #ff0055;
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: #fd4381;
  border-color: #ff4382;
}

.button--inverse {
  background: transparent;
  color: #ff0055;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #ff0055;
}

.button--danger {
  background: #830000;
  border-color: #830000;
}

.button--danger:hover,
.button--danger:active {
  background: #f34343;
  border-color: #f34343;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}

@media (max-width: 700px) {
  .button {
    font: inherit;
    padding: 0.25rem 1rem;
    border: 1px solid #ff0055;
    border-radius: 4px;
    background: #ff0055;
    color: white;
    cursor: pointer;
    margin-right: 0.1rem;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 0.4rem;
  }
}
@media (max-width: 350px) {
  .button {
    font: inherit;
    padding: 0.2rem 0.75rem;
    border: 1px solid #ff0055;
    border-radius: 4px;
    background: #ff0055;
    color: white;
    cursor: pointer;
    margin-right: 0.3rem;
    text-decoration: none;
    display: inline-block;
    margin-bottom: 0.4rem;
  }
}
@media (max-width: 1500px) {
  img {
    border-radius: 5px;
    align-self: center;
    max-width: 250px;
    max-height: 150px;
    z-index: 1;
  }
  .Title {
    font-size: 1.3rem;
  }
  .Price {
    font-size: 1rem;
  }
}
@media (max-width: 1200px) {
  img {
    border-radius: 5px;
    align-self: center;
    max-width: 200px;
    max-height: 100px;
    z-index: 1;
  }
  .Title {
    font-size: 1.1rem;
  }
  .Price {
    font-size: 0.8rem;
  }
}
@media (max-width: 900px) {
  img {
    border-radius: 5px;
    align-self: center;
    max-width: 175px;
    max-height: 75px;
    z-index: 1;
  }
  .Title {
    font-size: 0.9rem;
  }
  .Price {
    font-size: 0.6rem;
  }
}
@media (max-width: 600px) {
  img {
    border-radius: 5px;
    align-self: center;
    max-width: 200px;
    max-height: 75px;
    z-index: 1;
  }
}
@media (max-width: 380px) {
  img {
    border-radius: 5px;
    align-self: center;
    max-width: 180px;
    max-height: 60px;
    z-index: 1;
  }
}
