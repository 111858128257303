.button {
  font: inherit;
  padding: 0.5rem 2.5rem;
  border: 1px solid #ff0055;
  border-radius: 4px;
  background: #ff0055;
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background: #fd4381;
  border-color: #ff4382;
}

.button--inverse {
  background: transparent;
  color: #ff0055;
}

.button--inverse:hover,
.button--inverse:active {
  color: white;
  background: #ff0055;
}

.button--danger {
  background: #830000;
  border-color: #830000;
}

.button--danger:hover,
.button--danger:active {
  background: #f34343;
  border-color: #f34343;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background: #ccc;
  color: #979797;
  border-color: #ccc;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.8rem;
}

.button--big {
  font-size: 1.5rem;
}
@media (max-width: 700px){
  .button {
    font: inherit;
    padding: 0.25rem 1rem;
    border: 1px solid #ff0055;
    border-radius: 4px;
    background: #ff0055;
    color: white;
    cursor: pointer;
    margin-right: 0.5rem;
    text-decoration: none;
    display: inline-block;
  }
}
@media (max-width: 350px){
  .button {
    font: inherit;
    padding: 0.2rem 0.75rem;
    border: 1px solid #ff0055;
    border-radius: 4px;
    background: #ff0055;
    color: white;
    cursor: pointer;
    margin-right: 0.3rem;
    text-decoration: none;
    display: inline-block;
  }
}